<script src="./index.js"></script>
<style src="./style.scss" lang="scss" scoped/>
<template>
    <div class="">
        <div class="system-pages-title container">
            {{ $t('about-us') }}
        </div>
      <div class="moving-cleaning-heading-banner"
           :style="{ 'background-image': 'url(/static/about-us-banner.jpeg)' }">
            <div class="container">
                <div class="  d-flex justify-content-center ">
                    <div class="heading-content-container">
                        <div class="system-pages-subtitle d-md-flex">
                        <div class="me-1"> {{ $t('about-us-title-line-1')}}</div>
                        <div class="me-1"> {{ $t('about-us-title-line-2')}}</div>
                        <div class="me-1"> {{ $t('about-us-title-line-3')}}</div>
                        </div>
                    </div>

                </div>
            </div>
        </div>
        <div class="system-pages-content  d-flex justify-content-center">
            <div class="heading-content-container">
                <p>
                  {{ $t('about-us-paragraph-1') }}

                </p>
                <p>
                  {{ $t('about-us-paragraph-2') }}
                </p>
            </div>
        </div>
    </div>


</template>
